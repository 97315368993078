@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@400;700&display=swap');

html { font-size: 16px; }

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

.bold {
  font-weight: 900;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer base {
  body {
    @apply font-roboto text-text;
  }
}

@layer components {
  h1, h2, h3, h4, h5, h6 {
    @apply font-poppins text-heading;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
 
.social {
  display: inline-block;
  text-align: center;
}

@font-face {
  font-family: 'sakuna';
  src: url('/public/fonts/sakuna.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}