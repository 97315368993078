@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}
@keyframes blink-caret {
    50% { border-right-color: #6F4E37 }
    to { border-right-color: transparent }
}

.error {
    color: red;
    font-weight: bold;
}

.home {
    background-image: url('../../../public/images/coffee-bg.webp');
    background-repeat: repeat-y;
    background-size: 100% auto;
    padding-top: 8.5rem;
    height: calc(100vh - 8.5rem);
    display: flex;
    align-items: center;
    width: 100%;
    color: #6F4E37;

    .container {
        scroll-snap-type: y mandatory;
        overflow-y: auto;
        scrollbar-width: thin;
        width: 100%;
        height: calc(100vh - 10rem);
        
        .section {
            display: flex;
            flex-direction: row;
            height: calc(100vh - 12.5rem);
            scroll-snap-align: start;
            max-width: 100%;

            border: 4px orange solid;
            border-radius: 2rem;
            margin: 1rem 4.5rem;
            padding: 1rem;
            background: rgba(250, 238, 215, 0.7);

            .section-inner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 80%;
                margin: 0 1.5rem;

                .abilities {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }

                .paragraph {
                    display: flex;
                    flex-direction: row;
                    margin-top: 1rem;

                    &:first-of-type {
                        width: 100%;
                    }

                    .paragraph-image {
                        margin-right: 1rem;
                        color: black;
                        border-radius: 1rem;
                        display: flex;

                        img {
                            max-width: 22rem;
                            max-height: 6rem;
                        }
                    }

                    .paragraph-body {
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }
            }

            .section-image {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 20%;
                img {
                    border-radius: 1rem;
                }

                .me {
                    border: solid 0.25rem orange;
                    width: 20rem;
                }   
            }
        }
    }

    .header {
        background-color: transparent;
        position: fixed;
        top: 0;
        z-index: 1000;
        height: 6.5rem;

        .container {
            border-radius: 1rem;
            background: orange;
        }
    
        h1.logo {
            font-family: sakuna;
            font-size: 4rem;
            padding: 0 1rem;
            border: 0.25rem solid #6F4E37;
            border-radius: 1rem;
            -webkit-box-shadow: 8px 8px 5px 0px #6F4E37;
            -moz-box-shadow: 8px 8px 5px 0px #6F4E37;
            box-shadow: 8px 8px 5px 0px #6F4E37;

            .animated {
                white-space: nowrap;
                border-right: 0.5rem solid #6F4E37;
                overflow: hidden;
                animation: typing 4s steps(30, end) forwards, blink-caret 5s step-end forwards;
            }

            @media (max-width: 599px) {
                font-size: 2.5rem;
            }
        }
    }

    @media (max-width: 1024px) {
        .container {
            .section {
                height: auto;
                scroll-snap-align: unset;
                flex-direction: column;
                margin: 1rem 4rem;
                padding: 1rem;

                .section-image {
                    width: 50%;
                    align-self: center;
                    margin: 1rem;
                }

                .section-inner .paragraph {
                    flex-direction: column;

                    .paragraph-image {
                        img {
                            max-width: 12rem;
                            max-height: 4rem;
                        }
                    }
                }
                .paragraph-body {
                    font-size: 1rem;
                }
            }
        }
    }

    @media (max-width: 599px) {
        .container {
            .section {
                margin: 0.5rem;
                padding: 0.5rem;
                .paragraph-body {
                    font-size: 0.8rem;
                }
            }
        }
    }
}
